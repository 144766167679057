<template>
  <div class="c-description-section">
    <p class="desc-order">{{ data.order }}</p>
    <p class="desc-title">{{ data.title }}</p>
    <div class="desc-text-box"></div>
    <p class="desc-text" v-for="text of data.desc" :key="text">{{ text }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DescriptionSection',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style lang="scss" scoped>
.c-description-section {
  width: 100%;
}

.desc-order {
  @include font-style($c-main, 20, 700, 1px, 26px);
}

.desc-title {
  @include font-style($c-black, 24, bold, 1.2px, 26px);
  margin-top: 8px;
}
.desc-text-box {
  margin-top: 16px;
}

.desc-text {
  @include font-style($c-assist6, 16, normal, 0px, 24px);
}

@media screen and (min-width: $tablet) {
  .c-description-section {
  }

  .desc-order {
    @include font-style($c-main, 20, 800, 1px);
  }

  .desc-title {
    @include font-style($c-black, 30, bold, 0px, 42px);
  }

}
</style>
