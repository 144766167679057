<template>
  <p class="c-mi-title" :class="[`c-mi-title--color--${color}`]">
    {{ $attrs.title }}
  </p>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MiTitle',
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
});
</script>
<style lang="scss" scoped>
.c-mi-title {
  margin: 33px auto;
  text-align: center;
  @include font-style($c-black, 24, bold, 2.25px);

  &--color {
    &--white {
      color: $c-white;
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-mi-title {
    margin: 80px auto;
    @include font-size(45);

  }
}
</style>
