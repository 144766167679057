<template>
  <div class="v-licensing">
    <mi-title title="授權說明" color="white" />

    <div class="g-content">
      <div class="v-licensing__content">
        <div class="content-wrap">
          <div class="content-section">
            <!-- 01 -->
            <div class="content-section__text content-section__order-2">
              <description-section :data="licensingConfig['1']" />
            </div>
            <div class="content-section__img-box content-section__img content-section__order-1">
              <div class="content-section__img-box__img content-section__img-box__img__1"></div>
            </div>
          </div>
          <!-- 02, 03 -->
          <div class="content-section">
            <div class="content-section__text content-section__order-1">
              <description-section :data="licensingConfig['2']" />
              <div class="content-section__text__divide"></div>
              <description-section :data="licensingConfig['3']" />
            </div>
            <div class="content-section__img-box content-section__img content-section__order-2">
              <div
                class="content-section__img-box__img content-section__img-box__img__2"
                src="@/assets/licensing/img_2.png"
              ></div>
            </div>
          </div>
          <!-- 04, 05 -->
          <div class="content-section">
            <div class="content-section__text content-section__order-2">
              <description-section :data="licensingConfig['4']" />
              <div class="content-section__text__divide"></div>
              <description-section :data="licensingConfig['5']" />
            </div>
            <div class="content-section__img-box content-section__img content-section__order-1">
              <div
                class="content-section__img-box__img content-section__img-box__img__3"
                src="@/assets/licensing/img_3.png"
              ></div>
            </div>
          </div>
          <!-- 06, 07 -->
          <div class="content-section">
            <div class="content-section__text content-section__order-1">
              <description-section :data="licensingConfig['6']" />
              <div class="content-section__text__divide"></div>
              <description-section :data="licensingConfig['7']" />
            </div>
            <div class="content-section__img-box content-section__img content-section__order-2">
              <div
                class="content-section__img-box__img content-section__img-box__img__4 show-pc"
                src="@/assets/licensing/img_4.png"
              ></div>
            </div>
          </div>
        </div>

        <div class="btn-wrap">
          <mi-button @click="toContactPage">CONATCT US</mi-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import DescriptionSection from '@/components/Licensing/DescriptionSection.vue';
import licensingConfig from '@/config/licensingConfig';
import MiTitle from '@/components/Global/MiTitle.vue';
import MiButton from '@/components/Global/MiButton.vue';
import LicnesingBgImg from '@/assets/licensing/bg.png';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'Licensing',
  components: {
    DescriptionSection,
    MiTitle,
    MiButton,
  },
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    siteStore.setBackgroundImage(LicnesingBgImg);

    // 離開此頁時，清除背景
    onBeforeRouteLeave(() => {
      siteStore.setBackgroundImage(false);
    });

    const toContactPage = () => {
      router.push({ name: 'front-contact' });
    };

    return {
      licensingConfig,
      toContactPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.v-licensing {
  padding-top: 60px;
  padding-bottom: 150px;

  &__content {
    background-color: $c-white;
    border-radius: 10px;
    @include padding(27px 20px);
  }
}
.content-wrap {
  max-width: 988px;
  margin: 0 auto;
}

.content-section {
  @include flex(flex-start, flex-start, column);

  & + & {
    margin-top: 32px;
  }

  &__img-box {
    margin-top: 32px;
    width: 100%;

    &__img {
      border-radius: 10px;
      width: 100%;
      height: 40vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &__1 {
        background-image: url('~@/assets/licensing/img_1.png');
      }

      &__2 {
        background-image: url('~@/assets/licensing/img_2.png');
      }

      &__3 {
        background-image: url('~@/assets/licensing/img_3.png');
      }

      &__4 {
        background-image: url('~@/assets/licensing/img_4.png');
      }
    }
  }

  &__text {
    @include flex(flex-start, flex-start, column);

    &__divide {
      width: 100%;
      height: 1px;
      background-color: $c-assist2;
      margin: 36px 0;
    }
  }

  &__img {
    // margin-left: 122px;
  }
}

.btn-wrap {
  @include flex(center);
  margin-top: 56px;
  margin-bottom: 60px;
}

.show-tablet {
  display: none;
}
@media screen and (min-width: $mobile) {
  .content-section {
    &__img-box {
      &__img {
        height: 29vw;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .v-licensing {
    padding-top: 80px;
    padding-bottom: 150px;

    &__content {
      background-color: $c-white;
      border-radius: 10px;
      @include padding(115px 20px);
    }
  }
  .content-wrap {
    width: 100%;
    max-width: 988px;
    margin: 0 auto;
  }

  .content-section {
    // width: 100%;
    @include flex(space-between, center, row);

    & + & {
      margin-top: 32px;
    }

    &__img-box {
      margin-top: 0px;
      width: 400px;
      height: 400px;
      // width: 100%;

      &__img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &__1 {
          background-image: url('~@/assets/licensing/img_1.png');
        }

        &__2 {
          background-image: url('~@/assets/licensing/img_2.png');
        }

        &__3 {
          background-image: url('~@/assets/licensing/img_3.png');
        }

        &__4 {
          background-image: url('~@/assets/licensing/img_4.png');
        }
      }
    }

    &__text {
      width: 400px;
      height: 400px;
      @include flex(flex-start, flex-start, column);

      &__divide {
        width: 100%;
        height: 1px;
        background-color: $c-assist2;
        margin: 36px 0;
      }
    }

    &__img {
    }

    &__order-1 {
      order: 1;
    }

    &__order-2 {
      order: 2;
    }
  }

  .btn-wrap {
    @include flex(center);
    margin-top: 130px;
    margin-bottom: 0px;
  }

  .show-pc {
    display: block;
  }
}
</style>
