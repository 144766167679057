export default {
  1: {
    order: '01',
    title: '音樂片年租授權',
    desc: [
      '針對您的音樂需求及預算我們提供了不同的選擇方式：',
      '1. 選擇整套CD片承租。',
      '2. 選擇其中一套從中挑片。',
      '3. 選擇不受限的跨套精選挑片。',
      '我們提供多元化的組合方案滿足不同使用者的需求。 (如電視台、廣播電台、錄音室等)。',
    ],
  },
  2: {
    order: '02',
    title: '單曲授權',
    desc: [
      '您需要特定音樂來襯托您的作品嗎？我們擁有全台灣最大的音樂曲庫，是您最強的後盾，可隨時提供您試聽挑選，讓您的作品更加完美。',
    ],
  },
  3: {
    order: '03',
    title: '專案授權',
    desc: ['紀錄片、戲劇節目、工商簡介、有聲書…等。'],
  },
  4: {
    order: '04',
    title: '買斷式音樂授權',
    desc: ['一次付費，長期使用之買斷式音樂。'],
  },
  5: {
    order: '05',
    title: '買斷式效果授權',
    desc: [
      '你曾為你的視聽作品中的環境音，或者動畫片中的特殊效果音不足感到煩惱嗎？我們有各類型的音效片，一次付費長期使用，歡迎您來選購。',
    ],
  },

  6: {
    order: '06',
    title: '手機加值服務',
    desc: [
      '為了服務廣大聽眾，我們特別與隨身遊戲合作，將一些耳熟能詳的廣告音樂、熱門電視配樂或者是特別節日音樂(如農曆年音樂、聖誕節音樂)製作成手機鈴聲或來電答鈴，讓喜愛我們音樂的朋友能滿足即時需求。',
    ],
  },
  7: {
    order: '07',
    title: '各類產品音樂授權',
    desc: [
      '如果您有任何需要搭配音樂之產品，如玩具、手機、影音產品…等，請隨時來電洽詢，我們將竭誠為您服務。',
    ],
  },
};
